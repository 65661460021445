import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPLOAD = "upload";
export const GET_SUBMISSIONS = "getSubmissions";
export const GET_SINGLE_SUBMISSION = "getSingleSubmission";
export const UPDATE_SUBMISSION = "updateSubmission";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_LEADERBOARD = "setLeaderboard";
export const SET_DETAILS = "setDetails";
export const SET_MESSAGE = "setMessage";

const state = {
  errors: null,
  leaderboard: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  details: {},
  message: null
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("accounts/login/", credentials)
        .then(({ data }) => {
          // console.log("Here what post returns", data);
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          let errors = [];
          Object.keys(response.data).forEach(function(key) {
            if (response.data[key].constructor.name == "Array") {
              errors.push(...response.data[key]);
            } else {
              errors.push(response.data[key]);
            }
          });
          context.commit(SET_ERROR, errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [GET_SINGLE_SUBMISSION](context, id) {
    return new Promise(resolve => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
      }
      ApiService.get("submission/" + id)
        .then(response => {
          context.commit(SET_DETAILS, response.data);
          resolve(response.data);
        })
        .catch(() => resolve());
    });
  },
  [GET_SUBMISSIONS](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    }
    ApiService.get("submission").then(response =>
      context.commit(SET_LEADERBOARD, response.data)
    );
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      context.commit(PURGE_AUTH);
      ApiService.post("accounts/register/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          let errors = [];
          Object.keys(response.data).forEach(function(key) {
            if (response.data[key].constructor.name == "Array") {
              errors.push(...response.data[key]);
            } else {
              errors.push(response.data[key]);
            }
          });
          context.commit(SET_ERROR, errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("accounts/profile")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          if (response) {
            context.commit(SET_ERROR, response.data.errors);
          }
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPLOAD](context, data) {
    return new Promise(resolve => {
      if (JwtService.getToken()) {
        context.commit(SET_ERROR, []);
        ApiService.setHeader();
        ApiService.setMultipartHeader();
        ApiService.post("submission/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            let errors = [];
            Object.keys(response.data).forEach(function(key) {
              if (!response.data[key].constructor.name == "String") {
                response.data[key].forEach(function(elm) {
                  if (!elm.constructor.name == "String") {
                    errors.push(key + ": " + elm[key]);
                  } else {
                    if (key == "detail") {
                      errors.push(elm);
                    } else {
                      errors.push(key + ": " + elm);
                    }
                  }
                });
              } else {
                errors.push(response.data[key]);
              }
            });
            context.commit(SET_ERROR, errors);
            resolve();
          });
      } else {
        context.commit(PURGE_AUTH);
      }
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_SUBMISSION](context, payload) {
    return new Promise(resolve => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
      }
      context.commit(SET_MESSAGE, null);
      context.commit(SET_ERROR, []);
      ApiService.put("submission/" + payload.id + "/", payload)
        .then(response => {
          context.commit(SET_DETAILS, response.data);
          context.commit(
            SET_MESSAGE,
            "Updated at " + new Date().toLocaleString()
          );
          resolve(response.data);
        })
        .catch(({ response }) => {
          let errors = [];
          Object.keys(response.data).forEach(function(key) {
            response.data[key].forEach(function(elm) {
              errors.push(key + ": " + elm);
            });
          });
          context.commit(SET_ERROR, errors);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MESSAGE](state, message) {
    state.message = message;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    if ("token" in state.user) {
      JwtService.saveToken(state.user.token);
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_LEADERBOARD](state, leaderboard) {
    state.leaderboard = leaderboard;
  },
  [SET_DETAILS](state, details) {
    state.details = details;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
